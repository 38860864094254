import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  constructor(private popoverController: PopoverController, private navController:NavController,
    private api:ApiService, private help:UxHelpersService, private storage:Storage,
    private loadingController: LoadingController, private auth:AuthService) { }

  ngOnInit() {}
  async logOut(){
    this.popoverController.dismiss();
    // var loading = await this.loadingController.create({
    //   message: 'Requesting...',
    //   spinner: 'bubbles'
    // });
    // await loading.present();
    // if(this.api.isOnline){
    //   this.api.postWtkn('auth/logout')
    //   .subscribe((d : any)=>{
    //     // console.log(d)
    //     if(d.status){
    //       loading.dismiss();
          this.api.setToken(null);
          this.auth.setLoginStatus(false);
          this.storage.clear();
          this.help.presentToast('Log-out Successfully...');
          this.navController.navigateRoot(['/login']);
    //     }
    //     else{
    //       loading.dismiss();
    //       if(d.statuscode !="LOGI-01102")
    //         this.help.presentToast("Something went wrong. Try again...");
    //     }
    //   },(error) => { console.log(error);
    //     loading.dismiss();
    //     this.help.presentToast('Something went wrong. Try again...',2000);
    //   });
    // }
    // else{
    //   loading.dismiss();
    //   this.help.presentToast('You are offline. Retry.',2000);
    // }
  }

}