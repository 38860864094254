// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endPoint: 'https://cloudide.co.in/workspace/pitherp/public/api/v1/',
  remoteAssetsEndpoint: 'https://cloudide.co.in/workspace/pitherp/public/',
  remotePrintEndpoint: 'https://cloudide.co.in/workspace/pitherp/public/',
  isProduction: false,
  storagekeys: {
    authtoken: 'AUTH_TOKEN',
    islogin: 'login_status',
    loginDetails: 'login_details',
    dayDetails: 'day_details',
    daySyncTime: 'day_sync_time',
    salaryPaymentOpenCash: "salary_payment_openingcash",
  },
  buildVersion: 'Dev-V1.00.250202-B048-R01'
};
if (environment.isProduction) {
  environment.endPoint = 'https://pithbe.niferp.co.in/api/v1/';
  environment.remoteAssetsEndpoint = 'https://istore1.in1.justcloudify.net/nifpith/';
  environment.remotePrintEndpoint = 'https://pithbe.niferp.co.in/';
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
